.movie-list-container {
  overflow-x: hidden;
  width: auto;
  /* overflow-x: auto;
    white-space: nowrap;
    width: 100%;
    height: 200px; */
}

.carousel-control-prev-icon {
  position: absolute;
  top: 50%;
  right: 100%;
  transform: translateY(-50%);
  background-color: #DCDF39; /* Black background */
  border: 3px solid rgba(101, 94, 94, 0.63);
  border-radius: 50%;
  color: #EFBB4E;
  padding: 5px 10px;
  cursor: pointer;
  width: 40px;
  height: 40px;
}

@media screen and (max-width: 700px) {
  .carousel-control-prev-icon {
    display: none;
  }
  .carousel-control-next-icon {
    display: none;
  }
}

.carousel-control-next-icon {
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translateY(-50%);
  background-color: #DCDF39;
  border: 3px solid rgba(101, 94, 94, 0.63);
  border-radius: 50%;
  color: #EFBB4E;
  padding: 5px 10px;
  cursor: pointer;
  width: 40px;
  height: 40px;
}
.movie-list {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 5px;
  /* gap: 10px; */
  /* display: inline-flex; */
}

.movie-item {
  flex: 0 0 auto;
  width: 18rem;
  border: none;
  background-color: #353a3f00;
  padding: 5px;
}

.movie-item-latest-mobile {
  flex: 0 0 auto;
  width: 9rem;
  border: none;
  background-color: #353a3f00;
  padding: 8px;
  margin-bottom: -20px;
}
.movie-item-mobile {
  flex: 0 0 auto;
  width: 18rem;
  border: none;
  background-color: #353a3f;
  padding: 8px;
}

.movie-item img {
  border-radius: 8px;
  /* max-height: 150px; */
}

.movie-item-seasons img {
  border-radius: 8px;
  /* max-height: 150px; */
}

.movie-item {
  position: relative;
  margin-right: 10px;
  flex-shrink: 0;
  height: auto;
}

/* Add this CSS for the popup */
.popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  opacity: 0;
  transition: opacity 0.3s;
  pointer-events: none;
}

.movie-item:hover .popup {
  opacity: 1;
}

.slider-container {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.slider {
  display: flex;
  flex-wrap: nowrap;
  /* overflow-x: auto; */
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  height: 100px;
}

.slide {
  flex: 0 0 auto;
  scroll-snap-align: start;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px;
}

/* img {
    max-width: 100%;
    height: auto;
  } */

.title {
  font-size: 14px;
  margin-top: 5px;
}

.scroll-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.5);
  border: none;
  color: black;
  padding: 5px 10px;
  cursor: pointer;
}

.left {
  left: 0;
}

.right {
  right: 0;
}

.movie-list-container::-webkit-scrollbar {
  display: none;
}

/* Show custom scrollbar */
.movie-list-container {
  scrollbar-width: thin;
  scrollbar-color: #333333 #222222;
}

/* Track */
.movie-list-container::-webkit-scrollbar-track {
  background: #222222;
}

/* Handle */
.movie-list-container::-webkit-scrollbar-thumb {
  background-color: #333333;
  border-radius: 6px;
  border: 2px solid #222222;
}

/* Handle on hover */
.movie-list-container::-webkit-scrollbar-thumb:hover {
  /* border-radius: 6px; */
  border: 2px solid #222222;
  background-color: #555555;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

.loading-overlay .spinner-border {
  color: #fff; /* Color of the spinner */
}
.cookie-consent {
  background: rgb(235 67 40 / 90%);
  padding: 10px;
  color: #fff;
  font-size: 14px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

.cookie-consent a {
  color: #fff;
  text-decoration: underline;
}

.cookie-consent button {
  background: #008dc4;
  color: white;
  font-size: 16px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .cookie-consent {
    font-size: 12px;
    padding: 8px;
  }

  .cookie-consent button {
    font-size: 14px;
  }
}

@keyframes move {
  0% {
    transform: translateY(10px);
  }
  50% {
    transform: translateY(0px); /* Adjust the distance as needed */
  }
  100% {
    transform: translateY(10px);
  }
}

.moving {
  animation: move 1s linear infinite; /* Adjust duration and timing function as needed */
}
