/* CustomInput.css */
.custom-input-container {
  position: relative;
  margin-bottom: 0.5rem;
}

.custom-input-label {
  position: absolute;
  top: 5px;
  left: -5px;
  transform: translateY(50%);
  pointer-events: none;
  transition: transform 0.3s ease-in-out;
  color: #DCDF39;
}

.custom-input-field {
  border-radius: 0%;
  height: 60px;
  width: 100%;
  padding: 0.5rem;
  border: none;
  border-bottom: 1px solid #DCDF39;
  background-color: transparent;
  color: #ffffff;
  outline: none;
  transition: border-color 0.3s ease-in-out;
}

.custom-input-field:focus {
  border-color: rgba(235, 12, 12, 0) important;
}

.custom-input-field:focus + .custom-input-label,
.custom-input-field:not(:placeholder-shown) + .custom-input-label {
  transform: translateY(-22%) translateX(20%);
  font-size: 0.75rem;
  color: #DCDF39;
}

.custom-input-field::placeholder {
  color: #ffffff;
}

.custom-input-error {
  color: #dc35469b;
  font-size: 0.75rem;
  margin-top: 0.25rem;
}

.custom-input .form-control {
  border: none;
  border-bottom: 1px solid #DCDF39;
  border-radius: 0;
}

.form-control:focus {
  color: #ffffff;
  background-color: #42424300;
  border-color: #ffffff;
  box-shadow: none;
}

.form-control:disabled {
  background-color: #868686;
  opacity: 1;
}
